<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="6">
          <b-card-group>
            <b-card no-body class="p-4">
              <b-card-body>
                <b-form @submit.stop.prevent="onSubmit">
                  <h1>Нэвтрэх</h1>
                  <p class="text-muted">Удирдлагын хэсэг</p>
                  <b-alert :show="alert.dismissCountDown" variant="danger">
                    {{ alert.text }}
                  </b-alert>
                  <b-input-group class="mb-3">
                    <b-input-group-prepend
                      ><b-input-group-text
                        ><i class="icon-user"></i></b-input-group-text
                    ></b-input-group-prepend>
                    <b-form-input
                      type="email"
                      class="form-control"
                      placeholder="И-мэйл"
                      autocomplete="username email"
                      v-model="input.username"
                    />
                  </b-input-group>
                  <b-input-group class="mb-4">
                    <b-input-group-prepend
                      ><b-input-group-text
                        ><i class="icon-lock"></i></b-input-group-text
                    ></b-input-group-prepend>
                    <b-form-input
                      type="password"
                      class="form-control"
                      placeholder="Нууц үг"
                      autocomplete="current-password"
                      v-model="input.password"
                    />
                  </b-input-group>
                  <b-row>
                    <b-col cols="6">
                      <b-button type="submit" variant="primary" class="px-4"
                        >Нэвтрэх</b-button
                      >
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      input: {
        username: "",
        password: "",
      },
      alert: {
        text: "",
        dismissCountDown: 0,
      },
    };
  },
  methods: {
    onSubmit() {
      if (this.input.username != "" && this.input.password != "") {
        this.$http
          .post(
            this.$config.API_URL + "UserWebService/login",
            {
              password: this.input.password,
              username: this.input.username,
            },
            {
              headers: { "Content-Type": "application/x-www-form-urlencoded" },
              headers: { "Content-Type": "multipart/form-data" },
              emulateJSON: true,
            }
          )
          .then(
            (response) => {
              if (response.body.responseResultType == "SUCCESS") {
                //console.log(response.body)
                this.$store.commit("INIT");
                this.$store.commit("DATA", response.body);
                console.log(this.$router.history)
                console.log(this.$router.history.current.name !== 'Login')
                this.$router.history.current.name !== 'Login'
                  ? this.$router.go(-1)
                  : this.$router.push("/dashboard");
                // this.$router.push("/dashboard");
              } else {
                this.alert.text = "И-мэйл болон нууц үг буруу байна.";
                this.alert.dismissCountDown = 10;
              }
            },
            (response) => {
              this.showToast("Алдаа", "Алдаа гарсан байана", "danger");
            }
          );
      } else {
        this.alert.text = "И-мэйл болон нууц үг оруулаагүй байна.";
        this.alert.dismissCountDown = 10;
      }
    },
  },
};
</script>
